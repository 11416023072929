<template>
  <div>
    <Header :title="$t('reporting.lastIterations').toString()" />
    <TableHelper
      ref="table"
      :items="queuedJobs"
      :meta="meta"
      :fields="fields"
      :actions="actions"
      :enable-filter="true"
      :filterable="filterable"
      :filterable-field-blacklist="filterableFieldBlacklist"
      loading-key="loadQueuedJobs"
      @reload-data="loadQueuedJobs"
      @open-modal="showPayloadModal"
      @open-reporting="routeToLogs"
      @cancel-job="cancelJob"
    />
    <b-modal
      v-model="modal.show"
      size="xl"
      :title="modal.title.toString()"
      centered
      scrollable
      @hidden="closeModal"
    >
      <FormHelper
        v-if="typeof modal.data === 'object'"
        ref="form"
        v-model="modal"
        :form="formHelperForm"
        :config="formConfig"
      />
      <span v-else>{{ modal.data }}</span>
    </b-modal>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/helperFunctions";
import { mapGetters } from "vuex";
import ProcessIteration from "@/components/Workflows/processIteration";
import Header from "@/components/Tools/Header/Header.vue";
import TableHelper from "@/components/Tools/TableHelper/TableHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import Swal from "sweetalert2";

export default {
  components: {
    FormHelper,
    TableHelper,
    Header
  },
  props: ["workflow"],
  data() {
    return {
      actions: [
        {
          key: "openReporting",
          icon: "fal fa-search",
          emit: "open-reporting",
          tooltip: this.$t("workflowDesigner.openReporting")
        },
        {
          key: "openPayload",
          icon: "fal fa-info",
          emit: "open-modal",
          tooltip: this.$t("reporting.openPayload")
        },
        {
          key: "cancelJob",
          icon: "fal fa-times-circle",
          emit: "cancel-job",
          tooltip: this.$t("processManager.jobCancel"),
          condition: {
            field: "status",
            operator: "includes",
            value: [
              "process.info",
              "process.pending",
              "process.working",
              "process.soft_error",
              "process.started",
              "process.waiting",
              "process.warning"
            ]
          }
        }
      ],
      formHelperForm: [
        {
          type: "code",
          name: "dataString",
          lang: "json",
          disabled: true
        }
      ],
      formConfig: {
        snippetPrefix: "table",
        labelStacked: true,
        enableVariables: false
      },
      queuedJobs: [],
      status: [
        "process.aborted",
        "process.error",
        "process.info",
        "process.pending",
        "process.started",
        "process.stopped",
        "process.waiting",
        "process.warning",
        "process.working"
      ],
      fields: [
        {
          key: "process_name",
          label: this.$t("workflowDesigner.workflow"),
          sortable: true
        },
        {
          key: "content_identifier",
          label: this.$t("workflowDesigner.contentIdentifier"),
          sortable: true
        },
        {
          key: "status",
          label: this.$t("reporting.status"),
          sortable: true,
          type: "status",
          thClass: "text-center",
          thStyle: { width: "140px" }
        },
        {
          key: "duration",
          label: this.$t("reporting.duration"),
          sortable: true,
          type: "time"
        },
        {
          key: "queue",
          label: this.$t("reporting.queue"),
          sortable: true,
          thStyle: { width: "100px" }
        },
        {
          key: "started_at",
          label: this.$t("table.startedAt"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "finished_at",
          label: this.$t("table.finishedAt"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          sortable: true,
          type: "datetime"
        },
        {
          key: "started_by",
          label: this.$t("table.createdBy"),
          sortable: true
        }
      ],
      modal: {
        show: false,
        data: undefined,
        dataString: "",
        title: "",
        editMode: false
      },
      meta: {},
      // Filterable fields
      filterable: {},
      filterableFieldBlacklist: [
        "project_id",
        "process_id",
        "_id",
        "parent_flow_execution_id",
        "parameters"
      ]
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"]),
    ...mapGetters(["selectedProject"])
  },
  watch: {
    selectedProject: function () {
      this.loadQueuedJobs();
    }
  },
  mounted() {
    this.loadQueuedJobs();
  },
  methods: {
    formatDate,
    loadQueuedJobs(payload = {}) {
      addEventToLoadingQueue({ key: "loadQueuedJobs" });

      let params = this.requestParams();

      params.filter = [
        {
          key: "status",
          op: "equals",
          value: [
            "process.working",
            "process.waiting",
            "process.pending",
            "process.started",
            "process.warning",
            "process.info"
          ]
        }
      ];

      if (payload?.filter && payload.filter.length < 1) {
        delete payload.filter;
      }

      params = { ...params, ...payload };

      ProcessIteration.getAll(params)
        .then(response => {
          this.queuedJobs = response.data;
          this.meta = response.meta;
          this.filterable = response.meta.filterable ?? {};
          removeEventFromLoadingQueue({ key: "loadQueuedJobs" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    routeToLogs(item) {
      this.$router.push({
        name: "projectWorkflowsJobDetails",
        params: { id: item.item.process_id, jobId: item.item.id }
      });
    },
    showPayloadModal(item) {
      this.modal = {
        title: this.$t("reporting.payloadTitle"),
        show: true,
        data: item,
        dataString: JSON.stringify(item.item, null, "\t")
      };
    },
    closeModal() {
      this.modal = {
        show: false,
        data: undefined,
        dataString: undefined,
        title: ""
      };
    },
    cancelJob(iteration) {
      const statuses = ["process.error", "process.stopped", "process.aborted"];

      if (statuses.includes(iteration.item.status)) {
        this.$toast.fire({
          icon: "error",
          title: this.$t("queuedJobs.jobAbortFailed", {
            status: iteration.item.status
          })
        });
        return;
      }

      Swal.fire({
        title: this.$t("queuedJobs.deleteConfirm"),
        text: this.$t("queuedJobs.deleteConfirmText", {
          queue: iteration.item.process_name
        }),
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#5b64ee",
        showCloseButton: true,
        cancelButtonText: this.$t("queuedJobs.noCancel"),
        confirmButtonText: this.$t("queuedJobs.yesCancel")
      }).then(result => {
        if (result.isConfirmed) {
          addEventToLoadingQueue({ key: "cancelJob" });
          ProcessIteration.abort(iteration.item.id)
            .then(() => {
              removeEventFromLoadingQueue({
                key: "cancelJob",
                type: "success",
                prefix: "queuedJobs",
                name: "deleteSuccessText",
                data: {
                  queue: iteration.item.process_name
                }
              });
              this.loadQueuedJobs();
            })
            .catch(error => {
              removeEventFromLoadingQueue({ key: "cancelJob" });
              this.$error(error);
              this.loadQueuedJobs();
            });
        }
      });
    }
  }
};
</script>
